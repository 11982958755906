import React from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import Button from "./Button"

import styles from "./utils/elements.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const ResponsiveActionButtons = ({ isMobile, submit, next, back }) => (
  <div className="buttons is-centered">
    {back && (
      <Button
        onClick={() => {
          if (back.callback) {
            back.callback()
            return
          }
          navigate(back.link)
        }}
        className={classNames(
          "px-2 px-2-mobile mr-1 mr-1-mobile",
          {
            "is-hidden-desktop is-hidden-tablet": !!isMobile,
            "is-hidden-mobile": !isMobile,
          },
          styles["button_back"]
        )}
        size={isMobile ? "regular" : "medium"}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </Button>
    )}

    {next && (
      <Button
        onClick={() => {
          if (next.callback) {
            next.callback()
            return
          }
          navigate(next.link)
        }}
        className={classNames(
          "px-2 px-2-mobile mr-1 mr-1-mobile",
          {
            "is-hidden-desktop is-hidden-tablet": !!isMobile,
            "is-hidden-mobile": !isMobile,
          },
          styles["button_next"]
        )}
        size={isMobile ? "regular" : "medium"}
        isDisabled={next.disabled}
      >
        {next.label}
      </Button>
    )}

    {submit && (
      <Button
        className={classNames(
          "px-2 px-2-mobile mr-1 mr-1-mobile",
          {
            "is-hidden-desktop is-hidden-tablet": !!isMobile,
            "is-hidden-mobile": !isMobile,
          },
          styles["button_next"]
        )}
        size={isMobile ? "regular" : "medium"}
        type="submit"
        isLoading={submit.loading}
        isDisabled={submit.disabled}
      >
        {submit.label}
      </Button>
    )}
  </div>
)

const ActionButtons = ({ submit, next, back }) => (
  <div className="buttons is-centered">
    <ResponsiveActionButtons submit={submit} next={next} back={back} />
    <ResponsiveActionButtons isMobile submit={submit} next={next} back={back} />
  </div>
)

export default ActionButtons
