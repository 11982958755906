import React, { Fragment, useContext, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"

import { isBrowser, toCamelCase } from "../../../../services/general"
import { AppContext } from "../../../../context/AppContext"
import { parseFormField } from "../../../../services/airtable"
import { generateFormField } from "elements/Form/services/form"
import { generateInitialValues } from "../../../../services/context"
import {
  combineObjects,
  clearObjectProperties,
} from "../../../../services/general"

import { updatedPersonalDetailsValidationSchema } from "./utils/validationSchema"

const UpdatePersonalDetails = ({ pageContext, location }) => {
  const { state, dispatch } = useContext(AppContext)

  let formFields = pageContext.formFields

  formFields = formFields.map((field) => {
    if (field?.name === "fieldsToUpdate") {
      field.label = ""
      field.onChange = (event, option, setFieldValue, isChecked) => {
        if (!isChecked) {
          if (option !== "Delivery Address") {
            setFieldValue(`${toCamelCase(option)}`, "")
            setTimeout(() => {
              setFieldValue(`${toCamelCase(option)}`, "")
            }, 100)
          } else {
            setFieldValue(`${toCamelCase(option)}`, {
              addressType: "",
              streetAddress: "",
              houseNumber: "",
              province: {
                label: "",
                value: "",
              },
              city: {
                label: "",
                value: "",
              },
              barangay: {
                label: "",
                value: "",
              },
              notes: "",
              siteAddress: {
                label: "",
                value: "",
              },
            })

            setTimeout(() => {
              setFieldValue(`${toCamelCase(option)}`, {
                addressType: "",
                streetAddress: "",
                houseNumber: "",
                province: {
                  label: "",
                  value: "",
                },
                city: {
                  label: "",
                  value: "",
                },
                barangay: {
                  label: "",
                  value: "",
                },
                notes: "",
                siteAddress: {
                  label: "",
                  value: "",
                },
              })
            }, 100)
          }
        }
      }
    }

    return field
  })

  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  const handleSubmit = (values) => {
    let tempValues = { ...values }
    let updatedPersonalDetailsValues = {
      fieldsToUpdate: [...tempValues?.fieldsToUpdate],
      deliveryAddress: {
        addressType: "",
        streetAddress: "",
        houseNumber: "",
        province: {
          label: "",
          value: "",
        },
        city: {
          label: "",
          value: "",
        },
        barangay: {
          label: "",
          value: "",
        },
        officeAddress: {
          label: "",
          value: "",
        },
        notes: "",
      },
    }

    if (tempValues?.fieldsToUpdate) {
      for (let field of tempValues.fieldsToUpdate) {
        switch (field) {
          case "Email":
            updatedPersonalDetailsValues = {
              ...updatedPersonalDetailsValues,
              email: tempValues?.email,
            }
            break

          case "Delivery Address":
            updatedPersonalDetailsValues = {
              ...updatedPersonalDetailsValues,
              deliveryAddress: tempValues?.deliveryAddress,
            }

            break

          case "Mobile Number":
            updatedPersonalDetailsValues = {
              ...updatedPersonalDetailsValues,
              mobileNumber: tempValues?.mobileNumber,
            }
            break
          case "Site Address":
            updatedPersonalDetailsValues = {
              ...updatedPersonalDetailsValues,
              siteAddress: tempValues?.siteAddress,
            }
            break
          default:
            break
        }
      }
    }

    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        updatePersonalDetails: {
          ...combineObjects(
            clearObjectProperties(state.updatePersonalDetails),
            updatedPersonalDetailsValues
          ),
        },
      },
    })

    let medensureServices = JSON.parse(
      sessionStorage.getItem("medensureServices")
    )

    switch (true) {
      case medensureServices.includes("Upload updated prescription"):
        navigate("/medensure/updated-prescription")
        return
      case medensureServices.includes("Cancel refills"):
        navigate("/medensure/cancel-refills")
        return
      case medensureServices.includes("Book a free teleconsult"):
        navigate("/medensure/health-survey")
        return
      default:
        navigate("/medensure/summary")
        return
    }
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  return (
    <Layout
      path="/medensure"
      pageContext={pageContext}
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered>
        <Formik
          enableReinitialize
          validateOnChange={true}
          onSubmit={handleSubmit}
          initialValues={{
            ...generateInitialValues({ fields: formFields }),
            ...state.updatePersonalDetails,
            company: isBrowser() ? sessionStorage.getItem("company") || "" : "",
          }}
          validationSchema={updatedPersonalDetailsValidationSchema(
            isBrowser() ? sessionStorage.getItem("company") || "" : ""
          )}
        >
          {({ values, setFieldValue, isValid, submitCount, errors }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={sectionFormFields?.length > 1 ? section?.section : ""}
                  subtitle={section?.subtitle || ""}
                  id={section?.sectionId || ""}
                  className="mt-1 mb-3"
                >
                  {section?.message && (
                    <Message color={section?.messageColor || "light"}>
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: section?.message,
                        }}
                      />
                    </Message>
                  )}
                  {section?.fields.map((field) => {
                    if (values?.company.toLowerCase() === "accenture") {
                      if (field?.name === "deliveryAddress")
                        field.fieldNames = field?.fieldNames?.filter(
                          (fieldName) =>
                            ![
                              // "deliveryAddress.houseNumber",
                              "deliveryAddress.officeAddress",
                              "deliveryAddress.addressType",
                            ]?.includes(fieldName)
                        )
                    }

                    if (values?.company.toLowerCase() !== "foundever") {
                      field.options = field?.options?.filter(
                        (option) => option !== "Site Address"
                      )
                    }

                    if (values?.company.toLowerCase() === "foundever") {
                      field.options = field?.options?.filter(
                        (option) => option !== "Delivery Address"
                      )
                    }

                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: {
                              ...field,
                              disabled: !!field.disabled,
                            },
                            values,
                            setFieldValue,
                            context: {
                              state,
                              dispatch,
                            },
                            errors,
                            submitCount,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }
                    return null
                  })}
                </Section>
              ))}
              {!isValid && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                  <div className="content">
                    <ul>
                      {Object.keys(errors)?.map((error) => (
                        <li className="pb-0 mb-0">
                          {
                            formFields?.find((field) => field?.name === error)
                              ?.label
                          }
                        </li>
                      ))}
                    </ul>
                  </div>
                </Message>
              )}
              <ActionButtons
                back={{
                  label: "Back",
                  link: pageContext.backPath,
                }}
                submit={{
                  label: "Next",
                  disabled: !isValid || values?.fieldsToUpdate.length === 0,
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default UpdatePersonalDetails
